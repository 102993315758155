/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react'
// import { makeStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { AppContext } from '../../context/AppState'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'
import './style.css'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { currLang } from '../../utils/translations'
import {
  getIngredients,
  getAzureTranslation,
  getMapIngredientsCode
} from 'actions/AppActions'
import { uuidValidate } from 'utils/utils'
import { getIngredientsTemps } from 'utils/utils'

const IngredientsAromaticWineSection = () => {
  const { state } = useContext(AppContext)
  const { product } = state
  const [allIngredients, setAllIngredients] = useState([])
  const [mapIngredientCode, setMapIngredientCode] = useState({})
  const [initDone, setInitDone] = useState(false)
  const [translationLoaded, setTranslationLoaded] = useState(false)
  // questi  array sono per salvare i valori degli ingredienti provenienti dalle API
  const [rawMaterials, setRawMaterials] = useState([])
  const [acidityRegulators, setAcidityRegulators] = useState([])
  const [preservatives, setPreservatives] = useState([])
  const [antioxidants, setAntioxidants] = useState([])
  const [preservativesAndantioxidants, setpreservativesAndantioxidants] =
    useState([])
  const [agentsStabilising, setAgentsStabilising] = useState([])
  const [rawMaterialsAromatic, setRawMaterialsAromatic] = useState([])
  const [flavourEnhancersAromatic, setFlavourEnhancersAromatic] = useState([])
  const [anticakingAgentsAromatic, setAnticakingAgentsAromatic] = useState([])
  const [humectansAromatic, setHumectansAromatic] = useState([])
  const [sweetenersAromatic, setSweetenersAromatic] = useState([])
  const [coloringsAromatic, setColoringsAromatic] = useState([])
  const [acidityRegulatorsAromatic, setAcidityRegulatorsAromatic] = useState([])
  const [preservativesAromatic, setPreservativesAromatic] = useState([])
  const [antioxidantsAromatic, setAntioxidantsAromatic] = useState([])

  const [
    preservativesAndantioxidantsAromatic,
    setpreservativesAndantioxidantsAromatic
  ] = useState([])
  const [agentsStabilisingAromatic, setAgentsStabilisingAromatic] = useState([])
  const [acidulantsAromatic, setAcidulantsAromatic] = useState([])
  const [gellingAgentsAromatic, setGellingAgentsAromatic] = useState([])
  const [thickenersAromatic, setThickenersAromatic] = useState([])
  const [emulsifiersAromatic, setEmulsifiersAromatic] = useState([])
  const [gasses, setGasses] = useState([])
  const [others, setOthers] = useState([])
  const [othersIngredients, setOthersIngredients] = useState([])

  // questi array sono per salvare i valori degli ingredienti selezionati dall'utente
  const [rawMaterialsSelected, setRawMaterialsSelected] = useState([])
  const [acidityRegulatorsSelected, setAcidityRegulatorsSelected] = useState([])
  const [preservativesSelected, setPreservativesSelected] = useState([])
  const [antioxidantsSelected, setAntioxidantsSelected] = useState([])
  const [
    preservativesAndantioxidantsSelected,
    setpreservativesAndantioxidantsSelected
  ] = useState([])
  const [agentsStabilisingSelected, setAgentsStabilisingSelected] = useState([])
  const [rawMaterialsSelectedAromatic, setRawMaterialsSelectedAromatic] =
    useState([])
  const [
    flavourEnhancersSelectedAromatic,
    setFlavourEnhancersSelectedAromatic
  ] = useState([])
  const [
    anticakingAgentsSelectedAromatic,
    setAnticakingAgentsSelectedAromatic
  ] = useState([])
  const [humectantsSelectedAromatic, setHumectantsSelectedAromatic] = useState(
    []
  )
  const [sweetenersSelectedAromatic, setSweetenersSelectedAromatic] = useState(
    []
  )
  const [coloringsSelectedAromatic, setColoringsSelectedAromatic] = useState([])
  const [
    acidityRegulatorsSelectedAromatic,
    setAcidityRegulatorsSelectedAromatic
  ] = useState([])
  const [preservativesSelectedAromatic, setPreservativesSelectedAromatic] =
    useState([])
  const [antioxidantsSelectedAromatic, setAntioxidantsSelectedAromatic] =
    useState([])

  const [
    preservativesAndantioxidantsSelectedAromatic,
    setpreservativesAndantioxidantsSelectedAromatic
  ] = useState([])

  const [
    agentsStabilisingSelectedAromatic,
    setAgentsStabilisingSelectedAromatic
  ] = useState([])
  const [acidulantsSelectedAromatic, setAcidulantsSelectedAromatic] = useState(
    []
  )
  const [gellingAgentsSelectedAromatic, setGellingAgentsSelectedAromatic] =
    useState([])
  const [thickenersSelectedAromatic, setThickenersSelectedAromatic] = useState(
    []
  )
  const [emulsifiersSelectedAromatic, setEmulsifiersSelectedAromatic] =
    useState([])
  const [gassesSelected, setGassesSelected] = useState([])
  const [othersSelected, setOthersSelected] = useState([])
  const [othersIngredientsSelected, setOthersIngredientsSelected] = useState([])
  const [isBottledInProtectiveAtmosphere, setIsBottledInProtectiveAtmosphere] =
    useState(false)

  const [
    canBeBottledInProtectiveAtmosphere,
    setcanBeBottledInProtectiveAtmosphere
  ] = useState(false)
  const [isRawMaterialTitleHide, setIsRawMaterialTitleHide] = useState(false)

  const [order, setOrder] = useState([
    'rawMaterials',
    'acidityRegulators',
    'preservatives',
    'antioxidants',
    'agentsStabilising',
    'preservativesAndantioxidants',
    'gasses',
    'others',
    'othersIngredients'
  ])
  const [orderAromatic, setOrderAromatic] = useState([
    'aromatic_rawMaterials',
    'aromatic_flavourEnhancers',
    'aromatic_anticakingAgents',
    'aromatic_humectants',
    'aromatic_sweeteners',
    'aromatic_colorings',
    'aromatic_acidityRegulators',
    'aromatic_preservatives',
    'aromatic_antioxidants',
    'aromatic_preservativesAndantioxidants',
    'aromatic_agentsStabilising',
    'aromatic_acidulants',
    'aromatic_gellingAgents',
    'aromatic_thickeners',
    'aromatic_emulsifiers'
  ])
  // const orderAllCateogry = [
  //   'rawMaterials',
  //   'acidityRegulators',
  //   'preservatives',
  //   'antioxidants',
  //   'preservativesAndantioxidants',
  //   'agentsStabilising',
  //   'gasses',
  //   'others',
  //   'othersIngredients',
  //   'aromatic_rawMaterials',
  //   'aromatic_flavourEnhancers',
  //   'aromatic_anticakingAgents',
  //   'aromatic_humectants',
  //   'aromatic_sweeteners',
  //   'aromatic_colorings',
  //   'aromatic_acidityRegulators',
  //   'aromatic_preservatives',
  //   'aromatic_antioxidants',
  //   'aromatic_preservativesAndantioxidants',
  //   'aromatic_agentsStabilising',
  //   'aromatic_acidulants',
  //   'aromatic_gellingAgents',
  //   'aromatic_thickeners',
  //   'aromatic_emulsifiers'
  // ]

  const [orderAllCateogry, setOrderAllCateogry] = useState([])
  useEffect(() => {
    if (
      !product.ingredients ||
      !product.ingredients?.values ||
      !product.ingredients?.values?.length
    ) {
      return
    }
    const selectedIngredients = product.ingredients.values
    const categoriesOrder = []
    selectedIngredients?.forEach((ingredient) => {
      if (uuidValidate(ingredient)) {
        if (!categoriesOrder.includes('aromatic_rawMaterials')) {
          categoriesOrder.push('aromatic_rawMaterials')
        }
        return
      }
      for (const category in allIngredients?.values) {
        if (
          !Object.keys(allIngredients?.values[category])?.includes(ingredient)
        ) {
          continue
        }
        if (!categoriesOrder.includes(category)) {
          categoriesOrder.push(category)
        }
      }
    })
    setOrderAllCateogry(categoriesOrder)
  }, [product, allIngredients])

  const { t } = useTranslation('ingredients')
  let rawMaterialsIngredientsTemp = []
  let acidityRegulatorsIngredientsTemp = []
  let preservativesIngredientsTemp = []
  let antioxidantsIngredientsTemp = []
  let preservativesAndantioxidantsIngredientsTemp = []
  let agentsStabilisingIngredientsTemp = []
  let rawMaterialsIngredientsTempAromatic = []
  let flavourEnhancersIngredientsTempAromatic = []
  let anticakingAgentsIngredientsTempAromatic = []
  let humectantsIngredientsTempAromatic = []
  let sweetenersIngredientsTempAromatic = []
  let coloringsIngredientsTempAromatic = []
  let acidityRegulatorsIngredientsTempAromatic = []
  let preservativesIngredientsTempAromatic = []
  let antioxidantsIngredientsTempAromatic = []
  let preservativesAndantioxidantsIngredientsTempAromatic = []
  let agentsStabilisingIngredientsTempAromatic = []
  let acidulantsIngredientsTempAromatic = []
  let gellingAgentsIngredientsTempAromatic = []
  let thickenersIngredientsTempAromatic = []
  let emulsifiersIngredientsTempAromatic = []
  let gassesIngredientsTemp = []
  let othersIngredientsTemp = []
  let othersIngredientsIngredientsTemp = []
  let isBottledInProtectiveAtmosphereTemp
  let canBeBottledInProtectiveAtmosphereTemp
  let isRawMaterialTitleHideTemp
  let orderTemp = []
  const temporaryNormalIngredients =
    product.ingredients &&
    product.ingredients?.values &&
    product.ingredients?.values?.filter(
      (value) => !value.startsWith('aromatic') && !uuidValidate(value)
    )
  const temporaryAromaticIngredients =
    product.ingredients &&
    product.ingredients?.values &&
    product.ingredients?.values?.filter(
      (value) => value.startsWith('aromatic') || uuidValidate(value)
    )

  const useFetchData = () => {
    useEffect(() => {
      const fetchIngredients = async () => {
        const ingredientsResponse = await getIngredients()
        setAllIngredients(ingredientsResponse)
      }
      fetchIngredients()
    }, [])
  }

  useFetchData()

  const getMapIngredientCode = () =>
    useEffect(() => {
      const mapIngredientCode = async () => {
        const mapIngredientsCode = await getMapIngredientsCode()
        setMapIngredientCode(mapIngredientsCode)
      }
      mapIngredientCode()
    }, [])

  getMapIngredientCode()

  useEffect(() => {
    if (allIngredients && Object.keys(allIngredients)?.length > 0) {
      const keys = Object.keys(allIngredients.values)
      const splitIndex = keys.indexOf('aromatic_rawMaterials')

      const firstArray = []
      const secondArray = []
      const categoriesIngredients = []
      const categoriesIngredientsAromatic = []
      const allCategories = []

      keys.forEach((key, index) => {
        const innerKeys = Object.keys(allIngredients.values[key])
        if (index < splitIndex) {
          firstArray.push(...innerKeys)
          categoriesIngredients.push(key)
        } else {
          secondArray.push(...innerKeys)
          categoriesIngredientsAromatic.push(key)
        }
        allCategories.push(key)
      })

      const result = {}
      for (const key in allIngredients?.values) {
        if (Object.prototype.hasOwnProperty.call(allIngredients.values, key)) {
          result[key] = Object.keys(allIngredients.values[key])
        }
      }

      setRawMaterials(result.rawMaterials)
      setAcidityRegulators(result.acidityRegulators)
      setPreservatives(result.preservatives)
      setAntioxidants(result.antioxidants)
      setpreservativesAndantioxidants(result.preservativesAndantioxidants)
      setAgentsStabilising(result.agentsStabilising)
      setRawMaterialsAromatic(result.aromatic_rawMaterials)
      setFlavourEnhancersAromatic(result.aromatic_flavourEnhancers)
      setAnticakingAgentsAromatic(result.aromatic_anticakingAgents)
      setHumectansAromatic(result.aromatic_humectants)
      setSweetenersAromatic(result.aromatic_sweeteners)
      setColoringsAromatic(result.aromatic_colorings)
      setAcidityRegulatorsAromatic(result.aromatic_acidityRegulators)
      setPreservativesAromatic(result.aromatic_preservatives)
      setAntioxidantsAromatic(result.aromatic_antioxidants)
      setpreservativesAndantioxidantsAromatic(
        result.aromatic_preservativesAndantioxidants
      )
      setAgentsStabilisingAromatic(result.aromatic_agentsStabilising)
      setAcidulantsAromatic(result.aromatic_acidulants)
      setGellingAgentsAromatic(result.aromatic_gellingAgents)
      setThickenersAromatic(result.aromatic_thickeners)
      setEmulsifiersAromatic(result.aromatic_emulsifiers)
      setGasses(result.gasses)
      setOthers(result.others)
      setOthersIngredients(result.othersIngredients)
      setInitDone(true)
    }
  }, [allIngredients])

  // chiamata all'hook che fa il fetch dei dati

  useEffect(() => {
    setTranslationLoaded(false)
  }, [currLang])

  useEffect(() => {
    if (initDone) {
      if (
        product.ingredients &&
        product.ingredients?.values &&
        product.ingredients?.values?.length > 0
      ) {
        const fetchTranslation = async (product) => {
          const data = []
          for (const el of product.ingredients.values) {
            if (uuidValidate(el)) {
              const elem = product.includes.userDefinedIngredients.find(
                (value) => value.uuid === el
              )
              data.push({ uuid: el, text: elem.value })
            }
          }
          if (data.length) {
            const dataToSend = data?.map((el) => ({ text: el.text }))
            const translation = await getAzureTranslation(dataToSend, {
              'api-version': '3.0',
              to: currLang.split('-')[0]
            })
            data?.forEach((el, index) => {
              const elem = product.includes.userDefinedIngredients.find(
                (value) => value.uuid === el.uuid
              )
              elem.translation = translation[index].translations[0].text
            })
          }
          setTranslationLoaded(true)
        }
        fetchTranslation(product)
      }
    }
  }, [currLang, initDone])

  useEffect(() => {
    if (translationLoaded) {
      /*
      if (product.ingredients) {
      rawMaterialsIngredientsTemp = temporaryNormalIngredients.filter(el => rawMaterials.includes(el) || uuidValidate(el)).map(x =>
        {
          if (!uuidValidate(x)) {
            return t(`components.${x}`)
          }
          else {
            // logica per mandare la traduzione

            return product.includes.userDefinedIngredients.find(el => el.uuid === x).value
          }
        }
      )
      .reduce((prev, curr, index) => {
        return index !== rawMaterialsIngredientsTemp.length - 1 ? [...prev, curr, ', '] : [...prev, curr];
      }, []);
      rawMaterialsIngredientsTemp.pop() // remove last comma
      */
      if (product.ingredients && mapIngredientCode) {
        rawMaterialsIngredientsTemp = temporaryNormalIngredients
          ?.filter((el) => rawMaterials.includes(el))
          ?.map((x) => t(`components.${x}`))
          ?.reduce((prev, curr, index) => {
            return index !== rawMaterialsIngredientsTemp.length - 1
              ? [...prev, curr, ', ']
              : [...prev, curr]
          }, [])
        rawMaterialsIngredientsTemp?.pop() // remove last comma
        acidityRegulatorsIngredientsTemp = getIngredientsTemps(
          'acidityRegulators',
          acidityRegulators,
          temporaryNormalIngredients,
          mapIngredientCode,
          product,
          'components',
          t
        )
        /* acidityRegulatorsIngredientsTemp = temporaryNormalIngredients
          .filter((el) => acidityRegulators.includes(el))
          .map((x) => t(`components.${x}`))
          .reduce((prev, curr, index) => {
            return index !== acidityRegulatorsIngredientsTemp.length - 1
              ? [...prev, curr, ` ${t('andOr')} `]
              : [...prev, curr]
          }, [])
        acidityRegulatorsIngredientsTemp.pop() */ // remove last andOr
        preservativesIngredientsTemp = getIngredientsTemps(
          'preservatives',
          preservatives,
          temporaryNormalIngredients,
          mapIngredientCode,
          product,
          'components',
          t
        )
        preservativesAndantioxidantsIngredientsTemp = getIngredientsTemps(
          'preservativesAndantioxidants',
          preservativesAndantioxidants,
          temporaryNormalIngredients,
          mapIngredientCode,
          product,
          'components',
          t
        )

        /* preservativesIngredientsTemp = temporaryNormalIngredients
          .filter((el) => preservatives.includes(el))
          .map((x) => {
            if (
              x === 'lysozyme' ||
              x === 'potassiumBisulphite' ||
              x === 'sulphurDioxide2' ||
              x === 'potassiumMetabisulphite' ||
              x === 'sulphites'
            ) {
              return <b>{t(`components.${x}`)}</b>
            } else {
              return t(`components.${x}`)
            }
          })
          // .join(` ${t('andOr')} `)
          .reduce((prev, curr, index) => {
            return index !== preservativesIngredientsTemp.length - 1
              ? [...prev, curr, ', ']
              : [...prev, curr]
          }, [])
        preservativesIngredientsTemp.pop() */ // remove last comma
        antioxidantsIngredientsTemp = getIngredientsTemps(
          'antioxidants',
          antioxidants,
          temporaryNormalIngredients,
          mapIngredientCode,
          product,
          'components',
          t
        )
        /* antioxidantsIngredientsTemp = temporaryNormalIngredients
          .filter((el) => antioxidants.includes(el))
          .map((x) => {
            if (
              x === 'lysozyme2' ||
              x === 'potassiumBisulphite2' ||
              x === 'sulphurDioxide3' ||
              x === 'potassiumMetabisulphite2' ||
              x === 'sulphites2'
            ) {
              return <b>{t(`components.${x}`)}</b>
            } else {
              return t(`components.${x}`)
            }
          })
          // .join(` ${t('andOr')} `)
          .reduce((prev, curr, index) => {
            return index !== antioxidantsIngredientsTemp.length - 1
              ? [...prev, curr, ', ']
              : [...prev, curr]
          }, []) // remove last comma
        antioxidantsIngredientsTemp.pop() */
        agentsStabilisingIngredientsTemp = getIngredientsTemps(
          'agentsStabilising',
          agentsStabilising,
          temporaryNormalIngredients,
          mapIngredientCode,
          product,
          'components',
          t
        )
        /* agentsStabilisingIngredientsTemp = temporaryNormalIngredients
          .filter((el) => agentsStabilising.includes(el))
          .map((x) => t(`components.${x}`))
          .reduce((prev, curr, index) => {
            return index !== agentsStabilisingIngredientsTemp.length - 1
              ? [...prev, curr, ` ${t('andOr')} `]
              : [...prev, curr]
          }, []) // remove last andOr
        agentsStabilisingIngredientsTemp.pop() */
        gassesIngredientsTemp = getIngredientsTemps(
          'gasses',
          gasses,
          temporaryNormalIngredients,
          mapIngredientCode,
          product,
          'components',
          t
        )
        /* gassesIngredientsTemp = temporaryNormalIngredients
          .filter((el) => gasses.includes(el))
          .map((x) => t(`components.${x}`))
          .reduce((prev, curr, index) => {
            return index !== gassesIngredientsTemp.length - 1
              ? [...prev, curr, ', ']
              : [...prev, curr]
          }, [])
        gassesIngredientsTemp.pop() */ // remove last comma
        othersIngredientsTemp = getIngredientsTemps(
          'others',
          others,
          temporaryNormalIngredients,
          mapIngredientCode,
          product,
          'components',
          t
        )
        /* othersIngredientsTemp = temporaryNormalIngredients
          .filter((el) => others.includes(el))
          .map((x) => t(`components.${x}`))
          .reduce((prev, curr, index) => {
            return index !== othersIngredientsTemp.length - 1
              ? [...prev, curr, ', ']
              : [...prev, curr]
          }, [])
        othersIngredientsTemp.pop() */ // remove last comma
        othersIngredientsIngredientsTemp = getIngredientsTemps(
          'othersIngredients',
          othersIngredients,
          temporaryNormalIngredients,
          mapIngredientCode,
          product,
          'components',
          t
        )
        /* othersIngredientsIngredientsTemp = temporaryNormalIngredients
          .filter((el) => othersIngredients.includes(el))
          .map((x) => t(`components.${x}`))
          .reduce((prev, curr, index) => {
            return index !== othersIngredientsIngredientsTemp.length - 1
              ? [...prev, curr, ', ']
              : [...prev, curr]
          }, [])
        othersIngredientsIngredientsTemp.pop() */ // remove last comma

        isBottledInProtectiveAtmosphereTemp =
          product.ingredients?.metadata &&
          product.ingredients.metadata.isBottledInProtectiveAtmosphere

        canBeBottledInProtectiveAtmosphereTemp =
          product.ingredients?.metadata &&
          product.ingredients.metadata.canBeBottledInProtectiveAtmosphere

        isRawMaterialTitleHideTemp =
          product.metadata && product.metadata.isRawMaterialTitleHide

        orderTemp =
          product.ingredients?.metadata &&
          product.ingredients.metadata.order &&
          product.ingredients.metadata.order.length
            ? product.ingredients.metadata.order
            : orderAllCateogry
      }
      if (
        product.ingredients?.metadata &&
        product.ingredients.metadata.isAromaticWine
      ) {
        rawMaterialsIngredientsTempAromatic =
          temporaryAromaticIngredients.filter(
            (el) => rawMaterialsAromatic.includes(el) || uuidValidate(el)
          )

        const result = []
        for (let i = 0; i < rawMaterialsIngredientsTempAromatic.length; i++) {
          const x = rawMaterialsIngredientsTempAromatic[i]
          if (!uuidValidate(x)) {
            result.push(t(`componentsAromatic.${x}`))
          } else {
            const elem = product.includes?.userDefinedIngredients?.find(
              (value) => value.uuid === x
            )
            result.push(elem.translation)
          }
          if (i !== rawMaterialsIngredientsTempAromatic.length - 1) {
            result.push(', ')
          }
        }
        rawMaterialsIngredientsTempAromatic = result
        /* flavourEnhancersIngredientsTempAromatic = temporaryAromaticIngredients
          .filter((el) => flavourEnhancersAromatic.includes(el))
          .map((x) => t(`componentsAromatic.${x}`))
          .reduce((prev, curr, index) => {
            return index !== flavourEnhancersIngredientsTempAromatic.length - 1
              ? [...prev, curr, ', ']
              : [...prev, curr]
          }, [])
        flavourEnhancersIngredientsTempAromatic.pop() */ // remove last comma
        flavourEnhancersIngredientsTempAromatic = getIngredientsTemps(
          'aromatic_flavourEnhancers',
          flavourEnhancersAromatic,
          temporaryAromaticIngredients,
          mapIngredientCode,
          product,
          'componentsAromatic',
          t
        )
        /* anticakingAgentsIngredientsTempAromatic = temporaryAromaticIngredients
          .filter((el) => anticakingAgentsAromatic.includes(el))
          .map((x) => t(`componentsAromatic.${x}`))
          .reduce((prev, curr, index) => {
            return index !== anticakingAgentsIngredientsTempAromatic.length - 1
              ? [...prev, curr, ', ']
              : [...prev, curr]
          }, [])

        anticakingAgentsIngredientsTempAromatic.pop() */ // remove last comma
        anticakingAgentsIngredientsTempAromatic = getIngredientsTemps(
          'aromatic_anticakingAgents',
          anticakingAgentsAromatic,
          temporaryAromaticIngredients,
          mapIngredientCode,
          product,
          'componentsAromatic',
          t
        )
        /* humectantsIngredientsTempAromatic = temporaryAromaticIngredients
          .filter((el) => humectansAromatic.includes(el))
          .map((x) => t(`componentsAromatic.${x}`))
          .reduce((prev, curr, index) => {
            return index !== humectantsIngredientsTempAromatic.length - 1
              ? [...prev, curr, ', ']
              : [...prev, curr]
          }, [])
        humectantsIngredientsTempAromatic.pop() */ // remove last comma
        humectantsIngredientsTempAromatic = getIngredientsTemps(
          'aromatic_humectants',
          humectansAromatic,
          temporaryAromaticIngredients,
          mapIngredientCode,
          product,
          'componentsAromatic',
          t
        )

        /* sweetenersIngredientsTempAromatic = temporaryAromaticIngredients
          .filter((el) => sweetenersAromatic.includes(el))
          .map((x) => t(`componentsAromatic.${x}`))
          .reduce((prev, curr, index) => {
            return index !== sweetenersIngredientsTempAromatic.length - 1
              ? [...prev, curr, ', ']
              : [...prev, curr]
          }, [])
        sweetenersIngredientsTempAromatic.pop() */ // remove last comma
        sweetenersIngredientsTempAromatic = getIngredientsTemps(
          'aromatic_sweeteners',
          sweetenersAromatic,
          temporaryAromaticIngredients,
          mapIngredientCode,
          product,
          'componentsAromatic',
          t
        )
        /* coloringsIngredientsTempAromatic = temporaryAromaticIngredients
          .filter((el) => coloringsAromatic.includes(el))
          .map((x) => t(`componentsAromatic.${x}`))
          .reduce((prev, curr, index) => {
            return index !== coloringsIngredientsTempAromatic.length - 1
              ? [...prev, curr, ', ']
              : [...prev, curr]
          }, [])
        coloringsIngredientsTempAromatic.pop() */ // remove last comma
        coloringsIngredientsTempAromatic = getIngredientsTemps(
          'aromatic_colorings',
          coloringsAromatic,
          temporaryAromaticIngredients,
          mapIngredientCode,
          product,
          'componentsAromatic',
          t
        )
        /* acidityRegulatorsIngredientsTempAromatic = temporaryAromaticIngredients
          .filter((el) => acidityRegulatorsAromatic.includes(el))
          .map((x) => t(`componentsAromatic.${x}`))
          .reduce((prev, curr, index) => {
            return index !== acidityRegulatorsIngredientsTempAromatic.length - 1
              ? [...prev, curr, ` ${t('andOr')} `]
              : [...prev, curr]
          }, []) // remove last andOr
        acidityRegulatorsIngredientsTempAromatic.pop() */
        acidityRegulatorsIngredientsTempAromatic = getIngredientsTemps(
          'aromatic_acidityRegulators',
          acidityRegulatorsAromatic,
          temporaryAromaticIngredients,
          mapIngredientCode,
          product,
          'componentsAromatic',
          t
        )

        /* preservativesIngredientsTempAromatic = temporaryAromaticIngredients
          .filter((el) => preservativesAromatic.includes(el))
          .map((x) => {
            if (
              x === 'lysozyme' ||
              x === 'potassiumBisulphite' ||
              x === 'sulphurDioxide2' ||
              x === 'potassiumMetabisulphite' ||
              x === 'sulphites'
            ) {
              return <b>{t(`componentsAromatic.${x}`)}</b>
            } else {
              return t(`componentsAromatic.${x}`)
            }
          })
          // .join(` ${t('andOr')} `)
          .reduce((prev, curr, index) => {
            return index !== preservativesIngredientsTempAromatic.length - 1
              ? [...prev, curr, ', ']
              : [...prev, curr]
          }, [])
        preservativesIngredientsTempAromatic.pop() */ // remove last comma
        preservativesIngredientsTempAromatic = getIngredientsTemps(
          'aromatic_preservatives',
          preservativesAromatic,
          temporaryAromaticIngredients,
          mapIngredientCode,
          product,
          'componentsAromatic',
          t
        )

        preservativesAndantioxidantsIngredientsTempAromatic =
          getIngredientsTemps(
            'aromatic_preservativesAndantioxidants',
            preservativesAndantioxidantsAromatic,
            temporaryAromaticIngredients,
            mapIngredientCode,
            product,
            'componentsAromatic',
            t
          )
        /* antioxidantsIngredientsTempAromatic = temporaryAromaticIngredients
          .filter((el) => antioxidantsAromatic.includes(el))
          .map((x) => {
            if (
              x === 'lysozyme2' ||
              x === 'potassiumBisulphite2' ||
              x === 'sulphurDioxide3' ||
              x === 'potassiumMetabisulphite2' ||
              x === 'sulphites2'
            ) {
              return <b>{t(`componentsAromatic.${x}`)}</b>
            } else {
              return t(`componentsAromatic.${x}`)
            }
          })
          // .join(` ${t('andOr')} `)
          .reduce((prev, curr, index) => {
            return index !== antioxidantsIngredientsTempAromatic.length - 1
              ? [...prev, curr, ', ']
              : [...prev, curr]
          }, []) // remove last comma
        antioxidantsIngredientsTempAromatic.pop() */
        antioxidantsIngredientsTempAromatic = getIngredientsTemps(
          'aromatic_antioxidants',
          antioxidantsAromatic,
          temporaryAromaticIngredients,
          mapIngredientCode,
          product,
          'componentsAromatic',
          t
        )
        /* agentsStabilisingIngredientsTempAromatic = temporaryAromaticIngredients
          .filter((el) => agentsStabilisingAromatic.includes(el))
          .map((x) => t(`componentsAromatic.${x}`))
          .reduce((prev, curr, index) => {
            return index !== agentsStabilisingIngredientsTempAromatic.length - 1
              ? [...prev, curr, ` ${t('andOr')} `]
              : [...prev, curr]
          }, []) // remove last andOr
        agentsStabilisingIngredientsTempAromatic.pop()
        */
        agentsStabilisingIngredientsTempAromatic = getIngredientsTemps(
          'aromatic_agentsStabilising',
          agentsStabilisingAromatic,
          temporaryAromaticIngredients,
          mapIngredientCode,
          product,
          'componentsAromatic',
          t
        )
        /*acidulantsIngredientsTempAromatic = temporaryAromaticIngredients
          .filter((el) => acidulantsAromatic.includes(el))
          .map((x) => t(`componentsAromatic.${x}`))
          .reduce((prev, curr, index) => {
            return index !== acidulantsIngredientsTempAromatic.length - 1
              ? [...prev, curr, ', ']
              : [...prev, curr]
          }, [])
        acidulantsIngredientsTempAromatic.pop() */ // remove last comma
        acidulantsIngredientsTempAromatic = getIngredientsTemps(
          'aromatic_acidulants',
          acidulantsAromatic,
          temporaryAromaticIngredients,
          mapIngredientCode,
          product,
          'componentsAromatic',
          t
        )
        /* gellingAgentsIngredientsTempAromatic = temporaryAromaticIngredients
          .filter((el) => gellingAgentsAromatic.includes(el))
          .map((x) => t(`componentsAromatic.${x}`))
          .reduce((prev, curr, index) => {
            return index !== gellingAgentsIngredientsTempAromatic.length - 1
              ? [...prev, curr, ', ']
              : [...prev, curr]
          }, [])
        gellingAgentsIngredientsTempAromatic.pop()  */ // remove last comma
        gellingAgentsIngredientsTempAromatic = getIngredientsTemps(
          'aromatic_gellingAgents',
          gellingAgentsAromatic,
          temporaryAromaticIngredients,
          mapIngredientCode,
          product,
          'componentsAromatic',
          t
        )
        /* thickenersIngredientsTempAromatic = temporaryAromaticIngredients
          .filter((el) => thickenersAromatic.includes(el))
          .map((x) => t(`componentsAromatic.${x}`))
          .reduce((prev, curr, index) => {
            return index !== thickenersIngredientsTempAromatic.length - 1
              ? [...prev, curr, ', ']
              : [...prev, curr]
          }, [])
        thickenersIngredientsTempAromatic.pop()  */ // remove last comma
        thickenersIngredientsTempAromatic = getIngredientsTemps(
          'aromatic_thickeners',
          thickenersAromatic,
          temporaryAromaticIngredients,
          mapIngredientCode,
          product,
          'componentsAromatic',
          t
        )
        /* emulsifiersIngredientsTempAromatic = temporaryAromaticIngredients
          .filter((el) => emulsifiersAromatic.includes(el))
          .map((x) => t(`componentsAromatic.${x}`))
          .reduce((prev, curr, index) => {
            return index !== emulsifiersIngredientsTempAromatic.length - 1
              ? [...prev, curr, ', ']
              : [...prev, curr]
          }, [])
        emulsifiersIngredientsTempAromatic.pop() */ // remove last comma
        emulsifiersIngredientsTempAromatic = getIngredientsTemps(
          'aromatic_emulsifiers',
          emulsifiersAromatic,
          temporaryAromaticIngredients,
          mapIngredientCode,
          product,
          'componentsAromatic',
          t
        )
      }

      const ingredients = {
        rawMaterials: rawMaterialsIngredientsTemp,
        acidityRegulators: acidityRegulatorsIngredientsTemp,
        preservatives: preservativesIngredientsTemp,
        agentsStabilising: agentsStabilisingIngredientsTemp,
        preservativesAndantioxidants:
          preservativesAndantioxidantsIngredientsTemp,
        //preservativesAndantioxidants: [], // NOTE: questo è solo per backward compatibility con una vecchia impostazione. Prima di rimuovere, sistemare su db i dati sporchi
        antioxidants: antioxidantsIngredientsTemp,
        gasses: gassesIngredientsTemp,
        others: othersIngredientsTemp,
        othersIngredients: othersIngredientsIngredientsTemp
      }

      const ingredientsAromatic = {
        aromatic_rawMaterials: rawMaterialsIngredientsTempAromatic,
        aromatic_flavourEnhancers: flavourEnhancersIngredientsTempAromatic,
        aromatic_anticakingAgents: anticakingAgentsIngredientsTempAromatic,
        aromatic_humectants: humectantsIngredientsTempAromatic,
        aromatic_sweeteners: sweetenersIngredientsTempAromatic,
        aromatic_colorings: coloringsIngredientsTempAromatic,
        aromatic_acidityRegulators: acidityRegulatorsIngredientsTempAromatic,
        aromatic_preservatives: preservativesIngredientsTempAromatic,
        aromatic_antioxidants: antioxidantsIngredientsTempAromatic,
        aromatic_preservativesAndantioxidants:
          preservativesAndantioxidantsIngredientsTempAromatic,
        aromatic_agentsStabilising: agentsStabilisingIngredientsTempAromatic,
        aromatic_acidulants: acidulantsIngredientsTempAromatic,
        aromatic_gellingAgents: gellingAgentsIngredientsTempAromatic,
        aromatic_thickeners: thickenersIngredientsTempAromatic,
        aromatic_emulsifiers: emulsifiersIngredientsTempAromatic
      }

      // Filter both ingredients and ingredientsAromatic
      const filteredOrder = orderTemp?.filter(
        (category) =>
          (ingredients[category] && ingredients[category].length > 0) ||
          (ingredientsAromatic[category] &&
            ingredientsAromatic[category].length > 0)
      )

      const { nonAromatic, aromatic } = filteredOrder.reduce(
        (acc, category) => {
          if (ingredients[category] && ingredients[category].length > 0) {
            acc.nonAromatic.push(category)
          } else if (
            ingredientsAromatic[category] &&
            ingredientsAromatic[category].length > 0
          ) {
            acc.aromatic.push(category)
          }
          return acc
        },
        { nonAromatic: [], aromatic: [] }
      )

      setRawMaterialsSelected(rawMaterialsIngredientsTemp)
      setAcidityRegulatorsSelected(acidityRegulatorsIngredientsTemp)
      setPreservativesSelected(preservativesIngredientsTemp)
      setAntioxidantsSelected(antioxidantsIngredientsTemp)
      setpreservativesAndantioxidantsSelected(
        preservativesAndantioxidantsIngredientsTemp
      )
      setAgentsStabilisingSelected(agentsStabilisingIngredientsTemp)
      setRawMaterialsSelectedAromatic(rawMaterialsIngredientsTempAromatic)
      setFlavourEnhancersSelectedAromatic(
        flavourEnhancersIngredientsTempAromatic
      )
      setAnticakingAgentsSelectedAromatic(
        anticakingAgentsIngredientsTempAromatic
      )
      setHumectantsSelectedAromatic(humectantsIngredientsTempAromatic)
      setSweetenersSelectedAromatic(sweetenersIngredientsTempAromatic)
      setColoringsSelectedAromatic(coloringsIngredientsTempAromatic)
      setAcidityRegulatorsSelectedAromatic(
        acidityRegulatorsIngredientsTempAromatic
      )
      setPreservativesSelectedAromatic(preservativesIngredientsTempAromatic)
      setAntioxidantsSelectedAromatic(antioxidantsIngredientsTempAromatic)
      setpreservativesAndantioxidantsSelectedAromatic(
        preservativesAndantioxidantsIngredientsTempAromatic
      )
      setAgentsStabilisingSelectedAromatic(
        agentsStabilisingIngredientsTempAromatic
      )
      setAcidulantsSelectedAromatic(acidulantsIngredientsTempAromatic)
      setGellingAgentsSelectedAromatic(gellingAgentsIngredientsTempAromatic)
      setThickenersSelectedAromatic(thickenersIngredientsTempAromatic)
      setEmulsifiersSelectedAromatic(emulsifiersIngredientsTempAromatic)
      setGassesSelected(gassesIngredientsTemp)
      setOthersSelected(othersIngredientsTemp)
      setOthersIngredientsSelected(othersIngredientsIngredientsTemp)
      setIsBottledInProtectiveAtmosphere(isBottledInProtectiveAtmosphereTemp)
      setcanBeBottledInProtectiveAtmosphere(
        canBeBottledInProtectiveAtmosphereTemp
      )
      setIsRawMaterialTitleHide(isRawMaterialTitleHideTemp)
      setOrder(nonAromatic)
      setOrderAromatic(aromatic)
    }

    // eslint-disable-next-line
  }, [currLang, translationLoaded, mapIngredientCode])

  const isSmall = useMediaQuery('(max-width: 600px)')
  return (
    <div
      style={Object.assign(
        {},
        presentationStyle.ingredientContainer,
        isSmall ? { marginTop: '16px' } : { background: 'white' }
      )}
    >
      {product.ingredients &&
        product.ingredients.values &&
        product.ingredients.values.length > 0 && (
          <>
            <Typography
              variant='h5'
              sx={{ mb: 1, fontSize: '20px', fontWeight: 'bold' }}
            >
              {t('title')}
            </Typography>
            {order.map((el, index) => {
              let ingredientsWithComma = []
              let firstCategory = false
              if (parseInt(index) === 0) {
                firstCategory = true
              }
              switch (el) {
                case 'rawMaterials': {
                  if (parseInt(index) !== order.length - 1) {
                    if (rawMaterialsSelected.length > 0) {
                      ingredientsWithComma = rawMaterialsSelected
                      ingredientsWithComma.push(',')
                    }
                  } else {
                    if (rawMaterialsSelected.length > 0) {
                      ingredientsWithComma = rawMaterialsSelected
                      ingredientsWithComma.push(').')
                    }
                  }
                  return (
                    ingredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {firstCategory && `${t('wineSubtitle')} (`}
                        {!isRawMaterialTitleHide
                          ? `${t('category.rawMaterials')}: `
                          : ''}
                        {ingredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'acidityRegulators': {
                  if (parseInt(index) !== order.length - 1) {
                    if (acidityRegulatorsSelected.length > 0) {
                      ingredientsWithComma = acidityRegulatorsSelected
                      ingredientsWithComma.push(';')
                    }
                  } else {
                    if (acidityRegulatorsSelected.length > 0) {
                      ingredientsWithComma = acidityRegulatorsSelected
                      ingredientsWithComma.push(').')
                    }
                  }
                  return (
                    ingredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {firstCategory && `${t('wineSubtitle')} (`}{' '}
                        {t('category.acidityRegulators')}:{' '}
                        {/* {ingredientsWithComma.length > 2
                          ? `${t('contain')} `
                          : ''} */}
                        {ingredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'preservatives': {
                  if (parseInt(index) !== order.length - 1) {
                    if (preservativesSelected.length > 0) {
                      ingredientsWithComma = preservativesSelected
                      ingredientsWithComma.push(';')
                    }
                  } else {
                    if (preservativesSelected.length > 0) {
                      ingredientsWithComma = preservativesSelected
                      ingredientsWithComma.push(').')
                    }
                  }
                  return (
                    ingredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {firstCategory && `${t('wineSubtitle')} (`}
                        {t('category.preservatives')}: {ingredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'antioxidants': {
                  if (parseInt(index) !== order.length - 1) {
                    if (antioxidantsSelected.length > 0) {
                      ingredientsWithComma = antioxidantsSelected
                      ingredientsWithComma.push(';')
                    }
                  } else {
                    if (antioxidantsSelected.length > 0) {
                      ingredientsWithComma = antioxidantsSelected
                      ingredientsWithComma.push(').')
                    }
                  }
                  return (
                    ingredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {firstCategory && `${t('wineSubtitle')} (`}
                        {t('category.antioxidants')}: {ingredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'preservativesAndantioxidants': {
                  if (parseInt(index) !== order.length - 1) {
                    if (preservativesAndantioxidantsSelected.length > 0) {
                      ingredientsWithComma =
                        preservativesAndantioxidantsSelected
                      ingredientsWithComma.push(';')
                    }
                  } else {
                    if (preservativesAndantioxidantsSelected.length > 0) {
                      ingredientsWithComma =
                        preservativesAndantioxidantsSelected
                      ingredientsWithComma.push(').')
                    }
                  }
                  return (
                    ingredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {firstCategory && `${t('wineSubtitle')} (`}
                        {t('category.preservatives_antioxidants')}:{' '}
                        {ingredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'agentsStabilising': {
                  if (parseInt(index) !== order.length - 1) {
                    if (agentsStabilisingSelected.length > 0) {
                      ingredientsWithComma = agentsStabilisingSelected
                      ingredientsWithComma.push(';')
                    }
                  } else {
                    if (agentsStabilisingSelected.length > 0) {
                      ingredientsWithComma = agentsStabilisingSelected
                      ingredientsWithComma.push(').')
                    }
                  }
                  return (
                    ingredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {firstCategory && `${t('wineSubtitle')} (`}
                        {t('category.agentsStabilising')}:{' '}
                        {/* {ingredientsWithComma.length > 2
                          ? `${t('contain')}  `
                          : ''} */}
                        {ingredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'gasses': {
                  if (parseInt(index) !== order.length - 1) {
                    if (gassesSelected.length > 0) {
                      ingredientsWithComma = gassesSelected
                      ingredientsWithComma.push(';')
                    }
                  } else {
                    if (gassesSelected.length > 0) {
                      ingredientsWithComma = gassesSelected
                      ingredientsWithComma.push(').')
                    }
                  }
                  return (
                    ingredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {firstCategory && `${t('wineSubtitle')} (`}
                        {t('category.gasses')}: {ingredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'others': {
                  if (parseInt(index) !== order.length - 1) {
                    if (othersSelected.length > 0) {
                      ingredientsWithComma = othersSelected
                      ingredientsWithComma.push(';')
                    }
                  } else {
                    if (othersSelected.length > 0) {
                      ingredientsWithComma = othersSelected
                      ingredientsWithComma.push(').')
                    }
                  }
                  return (
                    ingredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {firstCategory && `${t('wineSubtitle')} (`}
                        {t('category.others')}: {ingredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'othersIngredients': {
                  if (parseInt(index) !== order.length - 1) {
                    if (othersIngredientsSelected.length > 0) {
                      ingredientsWithComma = othersIngredientsSelected
                      ingredientsWithComma.push(';')
                    }
                  } else {
                    if (othersIngredientsSelected.length > 0) {
                      ingredientsWithComma = othersIngredientsSelected
                      ingredientsWithComma.push('.)')
                    }
                  }
                  return (
                    ingredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {firstCategory && `${t('wineSubtitle')} (`}
                        {t('category.othersIngredients')}:{' '}
                        {ingredientsWithComma}
                      </Typography>
                    )
                  )
                }
                default: {
                  return null
                }
              }
            })}
            {orderAromatic.map((el, index) => {
              let aromaticIngredientsWithComma = []
              switch (el) {
                case 'aromatic_rawMaterials': {
                  if (parseInt(index) !== orderAromatic.length - 1) {
                    if (rawMaterialsSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma =
                        rawMaterialsSelectedAromatic
                      aromaticIngredientsWithComma.push(',')
                    }
                  } else {
                    if (rawMaterialsSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma =
                        rawMaterialsSelectedAromatic
                      aromaticIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    aromaticIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {!isRawMaterialTitleHide
                          ? `${t('categoryAromatic.aromatic_rawMaterials')}: `
                          : ''}
                        {aromaticIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'aromatic_flavourEnhancers': {
                  if (parseInt(index) !== orderAromatic.length - 1) {
                    if (flavourEnhancersSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma =
                        flavourEnhancersSelectedAromatic
                      aromaticIngredientsWithComma.push(';')
                    }
                  } else {
                    if (flavourEnhancersSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma =
                        flavourEnhancersSelectedAromatic
                      aromaticIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    aromaticIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('categoryAromatic.aromatic_flavourEnhancers')}:{' '}
                        {aromaticIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'aromatic_anticakingAgents': {
                  if (parseInt(index) !== orderAromatic.length - 1) {
                    if (anticakingAgentsSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma =
                        anticakingAgentsSelectedAromatic
                      aromaticIngredientsWithComma.push(';')
                    }
                  } else {
                    if (anticakingAgentsSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma =
                        anticakingAgentsSelectedAromatic
                      aromaticIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    aromaticIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('categoryAromatic.aromatic_anticakingAgents')}:{' '}
                        {aromaticIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'aromatic_humectants': {
                  if (parseInt(index) !== orderAromatic.length - 1) {
                    if (humectantsSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma = humectantsSelectedAromatic
                      aromaticIngredientsWithComma.push(';')
                    }
                  } else {
                    if (humectantsSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma = humectantsSelectedAromatic
                      aromaticIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    aromaticIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('categoryAromatic.aromatic_humectants')}:{' '}
                        {aromaticIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'aromatic_sweeteners': {
                  if (parseInt(index) !== orderAromatic.length - 1) {
                    if (sweetenersSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma = sweetenersSelectedAromatic
                      aromaticIngredientsWithComma.push(';')
                    }
                  } else {
                    if (sweetenersSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma = sweetenersSelectedAromatic
                      aromaticIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    aromaticIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('categoryAromatic.aromatic_sweeteners')}:{' '}
                        {aromaticIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'aromatic_colorings': {
                  if (parseInt(index) !== orderAromatic.length - 1) {
                    if (coloringsSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma = coloringsSelectedAromatic
                      aromaticIngredientsWithComma.push(';')
                    }
                  } else {
                    if (coloringsSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma = coloringsSelectedAromatic
                      aromaticIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    aromaticIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('categoryAromatic.aromatic_colorings')}:{' '}
                        {aromaticIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'aromatic_acidityRegulators': {
                  if (parseInt(index) !== orderAromatic.length - 1) {
                    if (acidityRegulatorsSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma =
                        acidityRegulatorsSelectedAromatic
                      aromaticIngredientsWithComma.push(';')
                    }
                  } else {
                    if (acidityRegulatorsSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma =
                        acidityRegulatorsSelectedAromatic
                      aromaticIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    aromaticIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('categoryAromatic.aromatic_acidityRegulators')}:{' '}
                        {/* {acidityRegulatorsSelectedAromatic.length > 2
                          ? `${t('contain')}  `
                          : ''} */}
                        {aromaticIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'aromatic_preservatives': {
                  if (parseInt(index) !== orderAromatic.length - 1) {
                    if (preservativesSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma =
                        preservativesSelectedAromatic
                      aromaticIngredientsWithComma.push(';')
                    }
                  } else {
                    if (preservativesSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma =
                        preservativesSelectedAromatic
                      aromaticIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    aromaticIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('categoryAromatic.aromatic_preservatives')}:{' '}
                        {aromaticIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'aromatic_antioxidants': {
                  if (parseInt(index) !== orderAromatic.length - 1) {
                    if (antioxidantsSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma =
                        antioxidantsSelectedAromatic
                      aromaticIngredientsWithComma.push(';')
                    }
                  } else {
                    if (antioxidantsSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma =
                        antioxidantsSelectedAromatic
                      aromaticIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    aromaticIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('categoryAromatic.aromatic_antioxidants')}:{' '}
                        {aromaticIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'aromatic_preservativesAndantioxidants': {
                  if (parseInt(index) !== orderAromatic.length - 1) {
                    if (
                      preservativesAndantioxidantsSelectedAromatic.length > 0
                    ) {
                      aromaticIngredientsWithComma =
                        preservativesAndantioxidantsSelectedAromatic
                      aromaticIngredientsWithComma.push(';')
                    }
                  } else {
                    if (
                      preservativesAndantioxidantsSelectedAromatic.length > 0
                    ) {
                      aromaticIngredientsWithComma =
                        preservativesAndantioxidantsSelectedAromatic
                      aromaticIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    aromaticIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t(
                          'categoryAromatic.aromatic_preservatives_antioxidants'
                        )}
                        : {aromaticIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'aromatic_agentsStabilising': {
                  if (parseInt(index) !== orderAromatic.length - 1) {
                    if (agentsStabilisingSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma =
                        agentsStabilisingSelectedAromatic
                      aromaticIngredientsWithComma.push(';')
                    }
                  } else {
                    if (agentsStabilisingSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma =
                        agentsStabilisingSelectedAromatic
                      aromaticIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    aromaticIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('categoryAromatic.aromatic_agentsStabilising')}:{' '}
                        {/* {aromaticIngredientsWithComma.length > 2
                          ? `${t('contain')}  `
                          : ''} */}
                        {aromaticIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'aromatic_acidulants': {
                  if (parseInt(index) !== orderAromatic.length - 1) {
                    if (acidulantsSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma = acidulantsSelectedAromatic
                      aromaticIngredientsWithComma.push(';')
                    }
                  } else {
                    if (acidulantsSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma = acidulantsSelectedAromatic
                      aromaticIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    aromaticIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('categoryAromatic.aromatic_acidulants')}:{' '}
                        {aromaticIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'aromatic_gellingAgents': {
                  if (parseInt(index) !== orderAromatic.length - 1) {
                    if (gellingAgentsSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma =
                        gellingAgentsSelectedAromatic
                      aromaticIngredientsWithComma.push(';')
                    }
                  } else {
                    if (gellingAgentsSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma =
                        gellingAgentsSelectedAromatic
                      aromaticIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    aromaticIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('categoryAromatic.aromatic_gellingAgents')}:{' '}
                        {aromaticIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'aromatic_thickeners': {
                  if (parseInt(index) !== orderAromatic.length - 1) {
                    if (thickenersSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma = thickenersSelectedAromatic
                      aromaticIngredientsWithComma.push(';')
                    }
                  } else {
                    if (thickenersSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma = thickenersSelectedAromatic
                      aromaticIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    aromaticIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('categoryAromatic.aromatic_thickeners')}:{' '}
                        {aromaticIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'aromatic_emulsifiers': {
                  if (parseInt(index) !== orderAromatic.length - 1) {
                    if (emulsifiersSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma = emulsifiersSelectedAromatic
                      aromaticIngredientsWithComma.push(';')
                    }
                  } else {
                    if (emulsifiersSelectedAromatic.length > 0) {
                      aromaticIngredientsWithComma = emulsifiersSelectedAromatic
                      aromaticIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    aromaticIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('categoryAromatic.aromatic_emulsifiers')}:{' '}
                        {aromaticIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                default: {
                  return null
                }
              }
            })}
            <br />
            {isBottledInProtectiveAtmosphere && (
              <Typography variant='body'>
                {t('category.isBottledInProtectiveAtmosphere')}.
              </Typography>
            )}

            {canBeBottledInProtectiveAtmosphere && (
              <Typography variant='body'>
                {t('category.protectedAtmosphere')}.
              </Typography>
            )}
          </>
        )}
    </div>
  )
}
export default IngredientsAromaticWineSection
