import {
  container,
  title,
  main,
  whiteColor,
  grayColor,
  primaryColor,
  mainRaised,
  hexToRgb
} from '../../material-kit-pro-react.js'
import { colors } from '@mui/material'

const presentationStyle = {
  '@global': {
    '#skeleton-background span': {
      borderRadius: 0,
      lineHeight: 'unset'
    }
  },
  fredoka: {
    fontFamily: "''Aleo', serif"
  },
  main: {
    ...main
    /* overflow: "hidden" */
  },
  mainRaised,
  parallax: {
    overflow: 'hidden'
  },
  container: {
    ...container,
    zIndex: 1
  },
  title: {
    color: grayColor[1],
    textDecoration: 'none',
    fontWeight: '900',
    marginTop: '30px',
    marginBottom: '25px',
    minHeight: '32px',
    fontFamily: "'Aleo', serif"
  },
  infoButton: {
    color: '#c8e0e8'
  },
  roundedAvatar: {
    height: '60px',
    width: '60px'
  },
  blockchainIcon: {
    height: '50%',
    width: '50%'
  },
  cardTitle: {
    '&, & a': {
      ...title,
      marginTop: '0',
      marginBottom: '0.75rem',
      minHeight: 'auto',
      fontSize: '2rem',
      fontFamily: "'Aleo', serif",
      textAlign: 'center'
    }
  },
  error: {
    backgroundColor: colors.red[700]
  },
  warning: {
    backgroundColor: colors.amber[700]
  },
  success: {
    backgroundColor: colors.green[700]
  },

  primaryColor: {
    backgroundColor: '#EF7C3C'
  },

  companyWebsite: {
    fontWeight: 'bold'
  },

  buttonShadowMobile: {
    boxShadow: '1px 1px 1px black'
  },
  dateTraceability: {
    display: 'block',
    textAlign: 'center',
    width: '47%',
    backgroundColor: '#fafafa',
    padding: '10px 2px',
    borderRadius: '20px',
    fontWeight: 'bold',
    boxShadow: '0px 1px 1px #999',
    textTransform: 'uppercase',
    margin: '15px 0'
  },
  dateTraceabilityMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
    padding: '5px 2px',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  dataTraceabilityMobileText: {
    fontWeight: 'bold'
  },
  cardTitleSmall: {
    ...title,
    width: '100%',
    marginTop: '20px',
    marginBottom: '2px',
    minHeight: 'auto',
    fontSize: '2.5rem',
    textAlign: 'left',
    fontFamily: "''Aleo', serif"
  },
  cardTitleDesktop: {
    ...title,
    width: '100%',
    marginTop: '2px',
    marginBottom: '2px',
    minHeight: 'auto',
    fontSize: '1.3rem',
    textAlign: 'left',
    fontFamily: "'Aleo', serif"
  },
  brand: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  proBadge: {
    position: 'relative',
    fontSize: '22px',
    textTransform: 'uppercase',
    fontWeight: '700',
    right: '-10px',
    padding: '10px 18px',
    top: '-30px',
    background: whiteColor,
    borderRadius: '3px',
    lineHeight: '22px',
    boxShadow: '0 5px 5px -2px rgba(' + hexToRgb(grayColor[25]) + ',.4)'
  },
  sectionButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
    alignItems: 'center'
  },
  cardBodyContainer: {
    width: '100%',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  chipPrimary: {
    margin: '0px 5px',
    backgroundColor: '#09A963',
    '&:hover, &:active, &:focus': {
      backgroundColor: '#00663A'
    }
  },
  videoWrapper: {
    position: 'relative',
    paddingBottom: '56.25%' /* 16:9 */,
    height: 0
  },
  videoWrapperFrame: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  contentContainer: {
    width: '100%',
    padding: '25px 25px',
    '@media (max-width: 600px)': {
      padding: '10px 0px'
    },
    marginTop: '30px',
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: colors.grey[50]
  },
  mapSwitchButton: {
    marginTop: 20
  },

  contentContainerTraceabilityView: {
    width: '100%',
    padding: '25px 25px',
    '@media (max-width: 600px)': {
      padding: '10px 0px'
    },
    marginTop: '30px',
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: '#ffffff'
  },
  rootTable: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  attributeGrid: {
    marginBottom: 20
  },
  menuContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    background: 'rgba(250, 250, 250, 0.6)',
    borderRadius: '4px',
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)' // Supporto blur per Safari
  },
  menuContainerMobile: {
    width: '100%',
    backgroundColor: '#fffffff7',
    display: 'flex',
    justifyContent: 'space-between'
  },
  mainMenu: {
    //marginTop: '4px',
    width: '55%',
    textAlign: 'center'
  },
  menuButtonPadding: {
    background: 'rgba(239, 124, 60, 0)',
    width: '100%',
    height: '100%'
  },
  menuButtonPaddingActive: {
    background: 'rgba(239, 124, 60, 0.1)',
    width: '100%',
    height: '100%'
  },
  mainMenuMobile: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // overflowX: 'scroll',
    // paddingBottom: '16px',
    // marginTop: '10px',
    padding: '10px 10px 10px 10px'
  },
  menuButtonMobilePadding: {
    padding: '20px 15px 2px 17px',
    borderRadius: '50%'
    // boxShadow: '0px 1px 1px #999'
  },
  mobileMenu: {
    paddingTop: 14,
    paddingBottom: 22,
    height: 'auto'
  },
  mobileMenuSelected: {
    color: '#09a963 !important'
  },
  lotContainer: {
    paddingTop: '36px',
    paddingBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '0 10px',
    marginTop: '30px',
    marginLeft: 0,
    marginRight: 0
    // backgroundColor: colors.grey[50]
  },
  attributeContainer: {
    // paddingTop: '16px',
    // paddingBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    //padding: '0 10px',
    marginLeft: 0,
    marginRight: 0
    // backgroundColor: colors.grey[50]
  },
  companyContainer: {
    paddingTop: '36px',
    paddingBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0 10px',
    marginTop: '30px'
  },
  disposalContainer: {
    paddingTop: '36px',
    paddingBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0 10px',
    marginTop: '30px'
  },
  disposalDivGridContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginLeft: 0,
    marginRight: 0
    // backgroundColor: colors.grey[50]
  },

  ingredientContainer: {
    paddingTop: '36px',
    paddingBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    width: '100%',
    padding: '0 10px',
    // marginTop: '30px',
    marginLeft: 0,
    marginRight: 0
    // backgroundColor: colors.grey[50]
  },
  contentGrid: {
    padding: '30px 30px 10px'
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '0 30px',
    marginBottom: '50px'
  },
  cardContainerMobile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '0 0px',
    marginBottom: '87px'
  },
  cardDescription: {
    color: colors.grey[600],
    fontFamily: "'Aleo', serif"
  },
  alignCenter: {
    padding: 0,
    alignSelf: 'center',
    width: 'auto'
  },
  logoContainer: {
    overflow: 'hidden',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%'
  },
  imgContainer: {
    overflow: 'hidden',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: 'auto'
    // position: 'absolute'
    // pointerEvents: 'none',
    // boxShadow: '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
  },
  mapContainer: {
    overflow: 'hidden',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    position: 'absolute'
    // pointerEvents: 'none',
    // boxShadow: '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
  },
  companyImgContainerMobile: {
    overflow: 'hidden',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '300px',
    padding: '5px'
    //marginTop: '30px'
    // pointerEvents: 'none',
    // boxShadow: '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
  },
  companyImgContainer: {
    overflow: 'hidden',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '350px'
    // pointerEvents: 'none',
    // boxShadow: '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
  },
  productImgContainer: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '200px',
    borderRadius: '6px',
    // pointerEvents: 'none',
    boxShadow:
      '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
  },
  textInfo: {
    color: primaryColor[1],
    fontWeight: 800
  },
  roundedCircle: {
    borderRadius: '50%'
  },
  bodySection: {
    margin: 0,
    padding: 0,
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  notFoundContainer: {
    width: '70%',
    height: '50vh',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
  },
  descriptionText: {
    fontSize: '16px',
    fontWeight: 'normal',
    textAlign: 'center'
  },
  h1Text: {
    color: '#09A963',
    lineHeight: 1,
    fontWeight: 'bold',
    zIndex: 12,
    textTransform: 'uppercase'
  },
  cardMedia: {
    height: 300,
    width: 300
  },
  cardMediaAdditionalImage: {
    height: '70%',
    width: '100%'
  },
  cardMediaMobile: {
    width: '100%',
    height: 'auto'
  },
  boldText: {
    color: colors.grey[4],
    fontWeight: 800
  },
  warningAlert: {
    borderRadius: 6,
    backgroundColor: '#fff39c',
    padding: '12px 16px'
  },
  masonryWrapper: {
    display: 'flex',
    flexFlow: 'column wrap',
    alignContent: 'center',
    // height: '1900px',
    counterReset: 'items',
    width: '100%',
    '&::before': {
      content: '',
      flexBasis: '100%',
      width: 0,
      order: 2
    },
    '&::after': {
      content: '',
      flexBasis: '100%',
      width: 0,
      order: 2
    }
  },
  masonryItem: {
    width: '48%',
    margin: 10,
    // '&::before': {
    //   counterIncrement: 'items',
    //   content: 'counter(items)'
    // },
    '&:nth-child(2n+1)': {
      order: 1
    },
    '&:nth-child(2n)': {
      order: 2
    }
  },
  textEllipsisMobile: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    fontFamily: "'Aleo', serif"
  },
  textEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '350px',
    whiteSpace: 'nowrap'
  },
  allergenePositiveCell: {
    backgroundColor: '#ffcdd2',
    borderLeft: '1px solid #e3e3e3',
    fontStyle: 'italic',
    fontWeight: 'bold'
  },
  tableContainer: {
    minWidth: 650
  },
  table: {
    fontFamily: "'Aleo', serif",
    marginTop: 20
  },
  allergeneNegativeCell: {
    backgroundColor: '#fff',
    borderLeft: '1px solid #e3e3e3'
  },
  nameTraceabilityMobile: {
    padding: 5,
    margin: 0,
    fontSize: '3em',
    color: '#3C4858',
    display: 'inline-block',
    width: '100%',
    fontFamily: "'Aleo', serif"
  },
  mediaAndMap: {
    margin: '25px',
    marginTop: '26px',
    padding: 0,
    height: 'auto',
    background: '#FFF',
    width: 'calc(100% - 50px)',
    borderRadius: '20px',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    overflow: 'hidden'
  },
  mediaAndMapFirstGridItem: {
    width: '100%',
    padding: 0,
    margin: 0,
    position: 'relative'
  },
  mediaAndMapSecondGridContainer: {
    padding: 0,
    margin: 0,
    position: 'absolute',
    height: '100%',
    width: '100%'
  },
  mediaAndMapSecondGridItem: {
    width: '100%',
    padding: 0,
    margin: 0,
    position: 'absolute',
    height: '100%',
    backgroundSize: 'cover'
  },
  nameTraceability: {
    ...title,
    display: 'block',
    textAlign: 'left',
    width: '100%',
    padding: '5px 2px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    margin: '15px 0',
    marginTop: '2px',
    marginBottom: '2px',
    minHeight: 'auto',
    fontSize: '1.5rem',
    fontFamily: "'Aleo', serif"
  },
  phasesName: {
    fontFamily: "'Aleo', serif",
    color: '#3C4858'
  },
  btnContainerOpenTimeStampHeader: {
    padding: '0 15px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left'
  },
  changeTile: {
    position: 'absolute',
    right: 5,
    top: 5,
    fontSize: '12px',
    zIndex: 1000,
    backgroundColor: 'rgb(239, 124, 60)',
    color: '#FFF'
  }
}

export default presentationStyle
