import React from 'react'
import Card from '../../components/Card/Card.js'
import useMediaQuery from '@mui/material/useMediaQuery'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle.js'
import Footer from '../../components/Footer/Footer.js'
import SelectLanguage from 'components/SelectLanguage/SelectLanguage.js'
import {
  Typography,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Box
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'

export default function Privacy() {
  const isSmall = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('privacyPolicy')

  return (
    <div
      style={{
        minHeight: '98vh',
        backgroundColor: isSmall ? '#fafafa' : ''
      }}
    >
      {/* <Box
        id='skeleton-background'
        style={{ height: isSmall ? '30vh' : '60vh', background: 'lightgray' }}
      /> */}
      <Box sx={{ position: 'absolute', top: '10px', right: '10px' }}>
        <SelectLanguage />
      </Box>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          padding: '0 10px',
          marginTop: '50px'
          //marginBottom: '50px'
        }}
      >
        <Card
          style={Object.assign(
            {},
            presentationStyle.main,
            // presentationStyle.mainRaised,
            isSmall
              ? {
                  padding: 10,
                  marginLeft: '0px',
                  marginRight: '0px',
                  borderRadius: '0px',
                  width: '95%'
                }
              : {
                  padding: 50,
                  width: '80%'
                }
          )}
        >
          <Container maxWidth='md' sx={{ padding: '5px' }}>
            <Typography variant='h4' gutterBottom>
              {/* E-Label by Trusty Informativa sulla Privacy */}
              {t('title')}
            </Typography>
            <Typography
              variant='body1'
              gutterBottom
              sx={{ textAlign: 'justify' }}
            >
              {t('subTitle')}
            </Typography>

            {/* Sezione dataOwner */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ textAlign: 'justify', fontWeight: 'bold' }}>
                  {t('dataOwner.title', { index: 1 })}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* Descrizione iniziale */}
                {/* <Typography sx={{ textAlign: 'justify', marginBottom: 2 }}>
                  {t('dataOwner.description')}
                </Typography> */}
                <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                  <li>
                    <Typography sx={{ textAlign: 'justify' }}>
                      {t('dataOwner.sections0', { name: 'Apra Spa' })}
                    </Typography>
                  </li>
                  <li>
                    <Typography sx={{ textAlign: 'justify' }}>
                      via G.Brodolini, 12 60035 Jesi (AN) - Italia
                    </Typography>
                  </li>
                  <li>
                    <Typography sx={{ textAlign: 'justify' }}>
                      +39 0731 22911
                    </Typography>
                  </li>
                  <li>
                    <Typography sx={{ textAlign: 'justify' }}>
                      info@apra.it
                    </Typography>
                  </li>
                  <li>
                    <Typography sx={{ textAlign: 'justify' }}>
                      P.I. - C.F. 0204351042
                    </Typography>
                  </li>
                  <li>
                    <Typography sx={{ textAlign: 'justify' }}>
                      REA n. 157070 Reg. Imp. di AN 02043510425
                    </Typography>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>

            {/* Sezione dataTypeCollection */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ textAlign: 'justify', fontWeight: 'bold' }}>
                  {t('dataTypeCollection.title', { index: 2 })}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* Descrizione iniziale */}
                <Typography sx={{ textAlign: 'justify', marginBottom: 2 }}>
                  {t('dataTypeCollection.description')}
                </Typography>
                <ol type='a'>
                  <li>
                    <Typography sx={{ textAlign: 'justify' }}>
                      {t('dataTypeCollection.sections0')}
                    </Typography>
                  </li>
                  <li>
                    <Typography sx={{ textAlign: 'justify' }}>
                      {t('dataTypeCollection.sections1')}
                    </Typography>
                  </li>
                </ol>
                {/* Nota finale */}
                <Typography sx={{ textAlign: 'justify' }}>
                  {t('dataTypeCollection.privacyNote')}
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* Sezione legalBasis */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ textAlign: 'justify', fontWeight: 'bold' }}>
                  {t('legalBasis.title', { index: 3 })}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* Descrizione iniziale */}
                <Typography sx={{ textAlign: 'justify', marginBottom: 2 }}>
                  {t('legalBasis.description', {
                    index: 2,
                    lett: 'a',
                    lett2: 'b'
                  })}
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* Sezione recipientsOfData */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ textAlign: 'justify', fontWeight: 'bold' }}>
                  {t('recipientsOfData.title', { index: 4 })}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ textAlign: 'justify', marginBottom: 2 }}>
                  {t('recipientsOfData.description')}
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* Sezione userRights */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ textAlign: 'justify', fontWeight: 'bold' }}>
                  {t('userRights.title', { index: 5 })}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ textAlign: 'justify', marginBottom: 2 }}>
                  {t('userRights.description')}
                </Typography>
                <Typography sx={{ textAlign: 'justify', marginBottom: 2 }}>
                  {t('userRights.description2')}
                </Typography>
                <Typography sx={{ textAlign: 'justify', marginBottom: 2 }}>
                  {t('userRights.description3')}
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* Sezione dataManager */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ textAlign: 'justify', fontWeight: 'bold' }}>
                  {t('dataManager.title', { index: 6 })}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {t('dataManager.description')}
                  <Link href='mailto:dpo@sesa.it'>dpo@sesa.it</Link>
                </Typography>
                {/* <Typography>
                  <Link href='mailto:dpo@sesa.it'>dpo@sesa.it</Link>
                </Typography> */}
              </AccordionDetails>
            </Accordion>
          </Container>

          {<Footer showLink={false} />}
        </Card>
      </div>
    </div>
  )
}
