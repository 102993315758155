import React from 'react'
import './App.css'
// import configuration from './configuration'
import PresentationPage from './views/PresentationPage/PresentationPage.js'
import OpentimestampsPage from './views/Opentimestamps/Opentimestamps.js'
import LegalNotes from './views/LegalNotes/LegalNotes.js'
import Privacy from './views/Privacy/Privacy.js'
import NotFound from './components/NotFound/NotFound'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { AppProvider } from './context/AppState'
import preval from 'preval.macro'
import { alog } from './utils/apioLog'

import './i18n'

function App() {
  // Stampiamo in console la data dell'ultima build dell'app
  const buildDate = preval`
const moment = require('moment')
const buildTimestamp = moment().format('DD/MM/YYYY HH:mm:ss')
module.exports = buildTimestamp
`

  alog('build date', buildDate, 'config', 'ok')
  // console.log(trying to work)
  return (
    <Router>
      <AppProvider>
        <Routes>
          <Route
            path='/gtin/:prod/lot/:lot'
            exact
            component={<PresentationPage />}
          />
          <Route
            path='/01/:prod/10/:lot'
            exact
            element={<PresentationPage />}
          />
          <Route path='/gtin/:prod' exact element={<PresentationPage />} />
          <Route path='/01/:prod' exact element={<PresentationPage />} />
          <Route
            path='/01/:prod/22/:version'
            exact
            element={<PresentationPage />}
          />
          <Route
            path='/opentimestamps'
            exact
            element={<OpentimestampsPage />}
          />
          <Route path='/01/:prod/legal-notes' exact element={<LegalNotes />} />
          <Route path='/01/:prod/privacy' exact element={<Privacy />} />
          <Route element={<NotFound />} />
        </Routes>
      </AppProvider>
    </Router>
  )
}

export default App
