import React, { useContext } from 'react'
// nodejs library that concatenates classes
// @mui/material components
// import { makeStyles } from '@mui/styles'
import { AppContext } from '../../context/AppState'
// import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet' // core components
// import RegularButton from '../../components/CustomButtons/Button.js'
import CardHeader from '../../components/Card/CardHeader.js'
//import TextWithUrl from '../../components/TextWithUrl/TextWithUrl'

import CharacteristicsPage from '../../components/CharacteristicsPage/CharacteristicsPage'
// import Avatar from '@mui/material/Avatar'

import CardBody from '../../components/Card/CardBody.js'
import Menu from '../../components/Menu/Menu'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle.js'
import Skeleton from 'react-loading-skeleton'
import { Box } from '@mui/material'
import SocialLinks from '../../components/SocialLinks/SocialLinks.js'
import { hideOrShowProperty } from '../../utils/utils'

// import BuyNowModal from '../../components/BuyNowModal/BuyNowModal.js'

// import { changeLanguage } from 'utils/translations'
// import CompanyVerificationBanner from '../../components/CompanyVerificationBanner'

// const useStyles = makeStyles(presentationStyle)

// main hook
export default function PresentationPageMobile() {
  // definisco stili e media query
  // const classes = useStyles()
  const { state } = useContext(AppContext)
  const { product, company, sectionActive, currLot, discover } = state
  // const { t } = useTranslation('presentationPage')
  // const handleClose = () => {
  //   setOpenBuyNow(false)
  // }
  // useEffect(() => {
  //  // window.scrollTo(0, 0)
  // }, [sectionActive])
  return (
    <>
      <Helmet>
        <title>
          {' '}
          {`${product.name} | ${company.name} | Lot ${currLot.lotNumber}`}
        </title>
        <meta name='description' content={product.description} />
        <meta
          property='og:image'
          content={
            product && product.profileImage && product.profileImage.cropped
              ? product.profileImage.cropped
              : null
          }
        />
      </Helmet>

      <CardHeader style={{ display: 'flex', justifyContent: 'center' }}>
        {(sectionActive === 1 ||
          sectionActive === 3 ||
          sectionActive === 4 ||
          sectionActive === 5 ||
          sectionActive === 6) &&
        hideOrShowProperty(discover, 'profileImage', 'product') &&
        product &&
        product.profileImage &&
        product.profileImage.cropped !== '' &&
        !product.profileImage.cropped.includes(
          '6ad54b23-6192-48e3-a45d-81355e85557e.png'
        ) ? ( // uuid del profile image default
          <Box
            component='div'
            display={
              sectionActive !== 1 &&
              sectionActive !== 5 &&
              sectionActive !== 6 &&
              sectionActive !== 3 &&
              sectionActive !== 4
                ? 'none'
                : 'block'
            }
            style={Object.assign(
              {},
              presentationStyle.imgContainer,
              presentationStyle.roundedCircle,
              {
                minWidth: '100px',
                width: '150px',
                height: '150px',
                backgroundImage: `url('${
                  product.profileImage.cropped !== ''
                    ? product.profileImage.cropped
                    : '/image-not-found.png'
                }')`
              }
            )}
          ></Box>
        ) : (
          <Box
            display={
              sectionActive !== 1 &&
              sectionActive !== 5 &&
              sectionActive !== 6 &&
              sectionActive !== 3 &&
              sectionActive !== 4
                ? 'none'
                : 'block'
            }
            style={Object.assign(
              {},
              presentationStyle.imgContainer,
              presentationStyle.roundedCircle
              // { minWidth: '100px', width: '200px', height: '200px' }
            )}
          >
            <Skeleton duration={0.7} width={150} height={150} circle={true} />
          </Box>
        )}
        {sectionActive === 2 &&
        company &&
        company.logo &&
        company.logo.cropped &&
        company.logo.original !== '' &&
        !company.logo.original.includes(
          'fd025f3d-80f2-40d9-abd6-44b257b50608'
        ) ? (
          <Box
            component='div'
            display={sectionActive !== 2 ? 'none' : 'block'}
            style={Object.assign(
              {},
              presentationStyle.logoContainer,
              presentationStyle.roundedCircle,
              {
                minWidth: '100px',
                width: '150px',
                height: '150px',
                backgroundImage: `url('${company.logo.cropped}')`,
                backgroundColor: '#ffffff'
              }
            )}
          ></Box>
        ) : (
          <Box
            display={sectionActive !== 2 ? 'none' : 'block'}
            style={Object.assign(
              {},
              presentationStyle.logoContainer,
              presentationStyle.roundedCircle,
              { minWidth: '100px', width: '150px', height: '20px' }
            )}
          >
            <Skeleton duration={0.7} width={150} height={150} circle={true} />
          </Box>
        )}
      </CardHeader>
      <CardBody style={{ padding: 0 }}>
        <div style={presentationStyle.cardBodyContainer}>
          {/* <CompanyVerificationBanner /> */}
          {/* nome */}
          {(sectionActive === 1 ||
            sectionActive === 5 ||
            sectionActive === 3 ||
            sectionActive === 4 ||
            sectionActive === 6) && (
            <>
              {!product?.metadata?.isNameFrontendHide ? (
                <h1
                  style={Object.assign({}, presentationStyle.cardTitle, {
                    fontSize: '32px',
                    textAlign: 'center'
                  })}
                >
                  {hideOrShowProperty(discover, 'name', 'product') &&
                    product &&
                    product.name}
                </h1>
              ) : null}
              {!product?.metadata?.isDescriptionFrontendHide && (
                <h3 style={presentationStyle.descriptionText}>
                  {hideOrShowProperty(discover, 'description', 'product') &&
                    product &&
                    product.description}
                </h3>
              )}
            </>
          )}
          {/* {sectionActive === 2 && (
            <h1
              style={Object.assign({}, presentationStyle.cardTitle, {
                fontSize: '32px',
                textAlign: 'center'
              })}
            >
              {company && company.name}
            </h1>
          )} */}
          {!product?.metadata?.isCompanyFrontendHide && <SocialLinks />}
          <CharacteristicsPage mobile={true} />
          {/* {sectionActive === 1 && (
            <>
              <CharacteristicsPage mobile={true} />
            </>
          )}
          {sectionActive === 2 && (
            <Box
              component='span'
              display={'block'}
              style={{ margin: '20px 0' }}
            >
              {company.name && company.description && (
                <>
                  <TextWithUrl
                    companyName={company.name}
                    text={company.description}
                  ></TextWithUrl>
                </>
              )}
            </Box>
          )} */}
          <Menu />
        </div>
      </CardBody>
    </>
  )
}
