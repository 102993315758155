import React from 'react'
// import apra from '../../assets/img/logo-apra.png'
import iWine from '../../assets/img/logo-iwine.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Box, Typography } from '@mui/material'
const Footer = ({ showLink }) => {
  const { t } = useTranslation('footer')
  const linkstyle = { color: 'white', fontSize: 'medium' }
  return (
    <Box className='footerBackground'>
      <Box className='footerLabel'>
        <img src={iWine} alt='I Wine' />
      </Box>

      <Typography className='impressumBottom' variant='h6'>
        {showLink ? (
          <Link style={linkstyle} to='legal-notes'>
            {t('legalNotes')}
          </Link>
        ) : (
          <Link
            style={linkstyle}
            to='#'
            onClick={() => window?.history?.back()}
          >
            {t('backLink')}
          </Link>
        )}
      </Typography>
      <Typography className='policyBottom' variant='h6'>
        {/* <a
          style={linkstyle}
          href='https://www.trusty.id/en/privacy-e-label'
          target='_blank'
          onClick={(e) => e.stopPropagation()}
          rel='noreferrer' // Evita di interferire con il click
        >
          {t('privacyPolicy')}
        </a> */}
        {showLink && (
          <Link style={linkstyle} to='privacy'>
            {t('privacyPolicy')}
          </Link>
        )}
      </Typography>

      {/* <Box className="footerLogo">
        <img src={apra} alt="I Wine"/>
      </Box> */}
    </Box>
  )
}

Footer.propTypes = {
  showLink: PropTypes.bool
}

export default Footer
