import React, { useContext, useEffect, useState } from 'react'
// // import { makeStyles } from '@mui/styles'
import { AppContext } from '../../context/AppState'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle.js'
// import { makeStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Avatar from '@mui/material/Avatar'
// const useStyles = makeStyles({
//   avatar: {
//     backgroundColor: blue[100],
//     color: blue[600]
//   }
// })
// const useStyles = makeStyles(presentationStyle)

const rightLink = (uuid) => {
  let link
  switch (uuid) {
    case '352a750d-9aa4-4dc8-a77b-34ed5e8f0eca': {
      link =
        'https://conad-vvtsd.ondigitalocean.app/coniglio-nazionale-sfuso.html'
      break
    }
    case 'eec03677-4a21-43a4-8d6e-ceac81364cf5': {
      // insalata di mare
      link = 'https://conad-vvtsd.ondigitalocean.app/insalata-di-mare.html'
      break
    }
    case '004c6304-ff29-473c-9a97-484df4ae8d85': {
      link =
        'https://conad-vvtsd.ondigitalocean.app/lasagne-al-ragu-spadellando.html'
      break
    }
    default:
      link = null
      break
  }
  return link
}

function VirtualAssistant() {
  const isSmall = useMediaQuery('(max-width: 600px)')

  // // const classes = useStyles()
  //   const { t } = useTranslation('infoFromProductor')
  const { state } = useContext(AppContext)
  const { company, product } = state
  const [bottomOffset, setBottomOffset] = useState(0)

  // const classes = useStyles()
  const link = rightLink(product.uuid)
  useEffect(() => {
    const menuContainer = document.getElementById('mobile-menu-container')
    setBottomOffset(menuContainer.offsetHeight + 10)
  }, [product])
  return link ? (
    <div
      style={{
        position: isSmall ? 'fixed' : 'relative',
        bottom: bottomOffset,
        right: isSmall ? '20px' : '60px',
        zIndex: '999'
      }}
    >
      {/* <span className='popover fredoka'>Scopri</span> */}
      <a href={link} target='_blank' rel='noopener noreferrer'>
        <Avatar
          style={presentationStyle.contentContainer}
          className='blob red'
          alt={`${company.name} logo`}
          src='/avatar-conad.png'
        />
      </a>
    </div>
  ) : null
}

export default VirtualAssistant
