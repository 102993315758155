import React from 'react'
import Flag from 'react-flags-select'
import {
  returnAbbr,
  returnAbbrShort,
  changeLanguage,
  currLang
} from '../../utils/translations.js'
import useMediaQuery from '@mui/material/useMediaQuery'
const SelectLanguage = () => {
  const [languageSelected, setLanguageSelected] = React.useState(
    returnAbbr(currLang)
  )
  const [languageSelectedView, setLanguageSelectedView] = React.useState(
    returnAbbrShort(currLang).toUpperCase()
  )

  const isSmall = useMediaQuery('(max-width: 600px)')
  // funzione che cambia lingua in base all'elemento selezionato
  const selectLanguage = (label) => {
    switch (label) {
      case 'BG':
      case 'BGR': {
        changeLanguage('bg-BG')
        setLanguageSelected(returnAbbr('bg-BG'))
        break
      }
      case 'CZ':
      case 'CS':
      case 'CES': {
        changeLanguage('cs-CZ')
        setLanguageSelected(returnAbbr('cs-CZ'))
        break
      }
      case 'DK':
      case 'DA':
      case 'DAN': {
        changeLanguage('da-DK')
        setLanguageSelected(returnAbbr('da-DK'))
        break
      }
      case 'DE':
      case 'DEU': {
        changeLanguage('de-DE')
        setLanguageSelected(returnAbbr('de-DE'))
        break
      }
      case 'GR':
      case 'EL':
      case 'ELL': {
        changeLanguage('el-GR')
        setLanguageSelected(returnAbbr('el-GR'))
        break
      }
      case 'GB':
      case 'EN':
      case 'ENG': {
        changeLanguage('en-US')
        setLanguageSelected(returnAbbr('en-US'))
        break
      }
      case 'ES':
      case 'ESP': {
        changeLanguage('es-ES')
        setLanguageSelected(returnAbbr('es-ES'))
        break
      }
      case 'EE':
      case 'ET':
      case 'EST': {
        changeLanguage('et-EE')
        setLanguageSelected(returnAbbr('et-EE'))
        break
      }
      case 'FI':
      case 'FIN': {
        changeLanguage('fi-FI')
        setLanguageSelected(returnAbbr('fi-FI'))
        break
      }
      case 'FR':
      case 'FRA': {
        changeLanguage('fr-FR')
        setLanguageSelected(returnAbbr('fr-FR'))
        break
      }
      case 'IE':
      case 'GA':
      case 'GLE': {
        changeLanguage('ga-IE')
        setLanguageSelected(returnAbbr('ga-IE'))
        break
      }
      case 'HR':
      case 'HRV': {
        changeLanguage('hr-HR')
        setLanguageSelected(returnAbbr('hr-HR'))
        break
      }
      case 'HU':
      case 'HUN': {
        changeLanguage('hu-HU')
        setLanguageSelected(returnAbbr('hu-HU'))
        break
      }
      case 'IT':
      case 'ITA': {
        changeLanguage('it-IT')
        setLanguageSelected(returnAbbr('it-IT'))
        break
      }
      case 'LT':
      case 'LIT': {
        changeLanguage('lt-LT')
        setLanguageSelected(returnAbbr('lt-LT'))
        break
      }
      case 'LV':
      case 'LAV': {
        changeLanguage('lv-LV')
        setLanguageSelected(returnAbbr('lv-LV'))
        break
      }
      case 'MT':
      case 'MLT': {
        changeLanguage('mt-MT')
        setLanguageSelected(returnAbbr('mt-MT'))
        break
      }
      case 'NL':
      case 'NLD': {
        changeLanguage('nl-NL')
        setLanguageSelected(returnAbbr('nl-NL'))
        break
      }
      case 'NO':
      case 'NOR': {
        changeLanguage('no-NO')
        setLanguageSelected(returnAbbr('no-NO'))
        break
      }
      case 'PL':
      case 'POL': {
        changeLanguage('pl-PL')
        setLanguageSelected(returnAbbr('pl-PL'))
        break
      }
      case 'PT':
      case 'PRT': {
        changeLanguage('pt-PT')
        setLanguageSelected(returnAbbr('pt-PT'))
        break
      }
      case 'RO':
      case 'RON': {
        changeLanguage('ro-RO')
        setLanguageSelected(returnAbbr('ro-RO'))
        break
      }
      case 'SK':
      case 'SLK': {
        changeLanguage('sk-SK')
        setLanguageSelected(returnAbbr('sk-SK'))
        break
      }
      case 'SI':
      case 'SL':
      case 'SLV': {
        changeLanguage('sl-SI')
        setLanguageSelected(returnAbbr('sl-SI'))
        break
      }
      case 'SE':
      case 'SV':
      case 'SWE': {
        changeLanguage('sv-SE')
        setLanguageSelected(returnAbbr('sv-SE'))
        break
      }
      default: {
        changeLanguage('it-IT')
        setLanguageSelected(returnAbbr('it-IT'))
        break
      }
    }
    setLanguageSelectedView(label)
    console.log('languageSelected', languageSelected)
  }
  return (
    <div
      style={{
        position: isSmall ? 'fixed' : 'relative',
        top: 0,
        right: isSmall ? '20px' : '60px',
        zIndex: '999',
        minWidth: isSmall ? 'auto' : '150px'
      }}
    >
      <Flag
        selectButtonClassName='customSelectFlagButton'
        selected={languageSelectedView}
        onSelect={(e) => selectLanguage(e)}
        countries={[
          'BG',
          'CZ',
          'DK',
          'DE',
          'GR',
          'GB',
          'ES',
          'EE',
          'FI',
          'FR',
          // 'IE',
          'HR',
          'HU',
          'IT',
          'LT',
          'LV',
          'MT',
          'NL',
          // 'NO',
          'PL',
          'PT',
          'RO',
          'SK',
          'SI',
          'SE'
        ]}
        customLabels={{
          // //'IE': 'Irish', // Commentato perché non presente nella lista fornita
          // //'NO': 'Norwegian', // Commentato perché non presente nella lista fornita
          BG: 'Български',
          CZ: 'Čeština',
          DK: 'Dansk',
          DE: 'Deutsch',
          GR: 'Ελληνικά',
          GB: 'English',
          ES: 'Español',
          EE: 'Eesti',
          FI: 'Suomi',
          FR: 'Français',
          // 'IE': 'Gaeilge', // Commentato perché non presente nella lista fornita
          HR: 'Hrvatski',
          HU: 'Magyar',
          IT: 'Italiano',
          LT: 'Lietuvių',
          LV: 'Latviešu',
          MT: 'Malti',
          NL: 'Nederlands',
          // 'NO': 'Norwegian', // Commentato perché non presente nella lista fornita
          PL: 'Polski',
          PT: 'Português',
          RO: 'Română',
          SK: 'Slovenčina',
          SI: 'Slovenščina',
          SE: 'Svenska'
        }}
      />
    </div>
  )
}

export default SelectLanguage
