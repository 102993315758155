import React, { useContext } from 'react'
// nodejs library that concatenates classes
// @mui/material components
// import { makeStyles } from '@mui/styles'
// import { useTranslation } from 'react-i18next'
import './style.css'
// core components
// import RegularButton from '../../components/CustomButtons/Button.js'
import CardHeader from '../../components/Card/CardHeader.js'
import { Helmet } from 'react-helmet'
import CardBody from '../../components/Card/CardBody.js'
import Menu from '../../components/Menu/Menu'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle.js'
//import Skeleton from 'react-loading-skeleton'
import { Box } from '@mui/material'
import SocialLinks from '../../components/SocialLinks/SocialLinks.js'
import { AppContext } from '../../context/AppState'
import { hideOrShowProperty } from '../../utils/utils'
import CharacteristicsPage from '../../components/CharacteristicsPage/CharacteristicsPage'

// import BuyNowModal from '../../components/BuyNowModal/BuyNowModal.js'

// import { changeLanguage } from 'utils/translations'
// import CompanyVerificationBanner from '../../components/CompanyVerificationBanner'

// const useStyles = makeStyles(presentationStyle)

// main hook
export default function PresentationPageDesktop() {
  // definisco stili e media query
  // const classes = useStyles()
  const { state } = useContext(AppContext)
  const { product, company, currLot, discover } = state
  // const handleClose = () => {
  //   setOpenBuyNow(false)
  // }
  return (
    <>
      <Helmet>
        <title>
          {' '}
          {`${product.name} | ${company.name} | Lot ${currLot.lotNumber}`}
        </title>
        <meta name='description' content={product.description} />
        <meta
          property='og:image'
          content={
            product &&
            product.profileImage &&
            product.profileImage.cropped !== ''
              ? product.profileImage.cropped
              : '/image-not-found.png'
          }
        />
      </Helmet>

      <CardHeader style={{ display: 'flex', justifyContent: 'center' }}>
        {hideOrShowProperty(discover, 'profileImage', 'product') &&
        product &&
        product.profileImage &&
        product.profileImage.cropped !== '' &&
        !product.profileImage.cropped.includes(
          '6ad54b23-6192-48e3-a45d-81355e85557e.png'
        ) ? ( // uuid del profile image default
          <Box
            component='div'
            display='block'
            style={Object.assign(
              {},
              presentationStyle.productImgContainer,
              presentationStyle.roundedCircle,
              {
                minWidth: '150px',
                width: '150px',
                height: '150px',
                backgroundImage: `url('${
                  product.profileImage.cropped !== ''
                    ? product.profileImage.cropped
                    : '/image-not-found.png'
                }')`
              }
            )}
          ></Box>
        ) : // <Box
        //   display='block'
        //   style={Object.assign(
        //     {},
        //     presentationStyle.imgContainer,
        //     presentationStyle.roundedCircle,
        //     { minWidth: '150px', width: '150px', height: '150px' }
        //   )}
        // >
        //   <Skeleton duration={0.7} width={150} height={150} circle={true} />
        // </Box>
        null}
      </CardHeader>

      <CardBody style={{ padding: 0 }}>
        <div style={presentationStyle.cardBodyContainer}>
          {hideOrShowProperty(discover, 'name', 'product') &&
          !product.metadata.isNameFrontendHide ? (
            <h1
              style={Object.assign({}, presentationStyle.cardTitle, {
                fontSize: '32px',
                textAlign: 'center'
              })}
            >
              {product && product.name}
            </h1>
          ) : null}
          {hideOrShowProperty(discover, 'social', 'product') &&
            !product.metadata?.isCompanyFrontendHide && <SocialLinks />}
          {/* <Box component="div" display='block' className="presentationDescription">
          </Box> */}

          {!product.metadata.isDescriptionFrontendHide && (
            <h3 style={presentationStyle.descriptionText}>
              {hideOrShowProperty(discover, 'description', 'product') &&
                product &&
                product.description}
            </h3>
          )}
          <CharacteristicsPage mobile={false} />
          <Menu />
        </div>
      </CardBody>
    </>
  )
}
