import React, { useContext, useState, useEffect } from 'react'
import { Box } from '@mui/material'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle.js'
// import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'
import { hideOrShowProperty } from '../../utils/utils'
import Button from '../CustomButtons/Button.js'
import organizationToRules from '../../organizationToRules'
import VirtualAssistant from '../VirtualAssistant/VirtualAssistant'
// const useStyles = makeStyles(presentationStyle)
// import productIcon from '../../assets/img/menu/product.svg'
import productIcon from '../../assets/img/menu/product.svg'
import companyIcon from '../../assets/img/menu/company.svg'
// import nutritionalIcon from '../../assets/img/menu/nutritional.svg'
// import specificationsIcon from '../../assets/img/menu/nutritional.svg'
import disposalIcon from '../../assets/img/menu/disposal.svg'

const MenuMobile = () => {
  const { state, dispatch } = useContext(AppContext)
  const { sectionActive, discover, company } = state

  // funzione che apre il menu mobile
  // const handleMenuOpen = event => {
  //   dispatch({
  //     type: 'SET_ANCHOR_MENU',
  //     payload: document.getElementById('mobile-menu-container')
  //   })
  // }

  // funzione che chiude il menu mobile
  const handleMenuClose = () => {
    dispatch({
      type: 'SET_ANCHOR_MENU',
      payload: null
    })
  }

  // funzione che cambia la sezione visualizzata
  const changeSection = (event, section) => {
    dispatch({
      type: 'SET_MENU_SECTION',
      payload: section
    })
    dispatch({
      type: 'SET_CLICK_MENU_SECTION',
      payload: true
    })
    handleMenuClose()
  }

  // const classes = useStyles()
  const { t } = useTranslation('menu')

  const [scrollY, setScrollY] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      if (!scrollY) {
        if (window.scrollY > 20) {
          setScrollY(10)
        } else {
          setScrollY(null)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <Box
        id='desktop-menu-container'
        component='div'
        position={'fixed'}
        top={0}
        zIndex={'tooltip'}
        boxShadow={3}
        style={presentationStyle.menuContainer}
        sx={{ width: '100%', height: `${scrollY}%` }}
      ></Box>
      <Box
        id='mobile-menu-container'
        component='div'
        position={'fixed'}
        bottom={0}
        zIndex={'tooltip'}
        boxShadow={3}
        style={presentationStyle.menuContainerMobile}
      >
        {/* {isSmall &&
          <Footer />
        } */}
        <Box
          component='div'
          style={presentationStyle.mainMenuMobile}
          sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}
        >
          {hideOrShowProperty(discover, 'allergenes', 'product') &&
            (Object.keys(state.product.nutritionalValue).length > 0 ||
              state?.product?.ingredients?.metadata?.isAromaticWine ||
              state?.product?.ingredients?.values?.length > 0) && (
              // <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '25%' }}>
              <Button
                onClick={() => changeSection(null, 4)}
                style={
                  sectionActive !== 4
                    ? presentationStyle.menuButtonPadding
                    : presentationStyle.menuButtonPaddingActive
                }
              >
                <div style={presentationStyle.sectionButton}>
                  <div className='smallMenuButton'>
                    <img
                      src={productIcon}
                      width='25'
                      alt='nutritional value icon'
                    />
                  </div>
                  <h6
                    style={{
                      color: '#3C4858',
                      fontSize: '10px',
                      marginBottom: 0
                    }}
                  >
                    {t('allergenes')}
                  </h6>
                </div>
              </Button>
              // </div>
            )}

          {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '25%' }}> */}
          {hideOrShowProperty(discover, 'waste', 'product') &&
          state.product.waste != null
            ? state.product.waste.length > 0 && (
                <Button
                  onClick={() => changeSection(null, 6)}
                  style={
                    sectionActive !== 6
                      ? presentationStyle.menuButtonPadding
                      : presentationStyle.menuButtonPaddingActive
                  }
                >
                  <div style={presentationStyle.sectionButton}>
                    <div className='smallMenuButton'>
                      <img src={disposalIcon} width='25' alt='disposal icon' />
                    </div>
                    <h6
                      style={{
                        color: '#3C4858',
                        fontSize: '10px',
                        marginBottom: 0
                      }}
                    >
                      {t('waste')}
                    </h6>
                  </div>
                </Button>
              )
            : null}
          {/* </div> */}
          {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '25%' }}> */}
          {!state.product.metadata?.isCompanyFrontendHide && (
            <Button
              onClick={() => changeSection(null, 2)}
              style={
                sectionActive !== 2
                  ? presentationStyle.menuButtonPadding
                  : presentationStyle.menuButtonPaddingActive
              }
            >
              <div style={presentationStyle.sectionButton}>
                <div className='smallMenuButton'>
                  <img src={companyIcon} width='25' alt='company icon' />
                </div>
                <h6
                  style={{
                    color: '#3C4858',
                    fontSize: '10px',
                    marginBottom: 0
                  }}
                >
                  {t('company')}
                </h6>
              </div>
            </Button>
          )}
          {/* {state.product.attributes?.length > 0 && (
            <Button
              onClick={() => changeSection(null, 1)}
              style={
                sectionActive !== 1
                  ? presentationStyle.menuButtonPadding
                  : presentationStyle.menuButtonPaddingActive
              }
            >
              <div style={presentationStyle.sectionButton}>
                <div className='smallMenuButton'>
                  <img
                    src={specificationsIcon}
                    width='25'
                    alt='specification icon'
                  />
                </div>
                <h6 style={{ color: '#3C4858', fontSize: '10px'  , marginBottom: 0}}>
                  {t('specifications')}
                </h6>
              </div>
            </Button>
          )} */}
          {/* </div> */}
        </Box>
      </Box>
      {organizationToRules[company.organizationId] &&
      organizationToRules[company.organizationId].virtualAssistant ? (
        <VirtualAssistant />
      ) : null}
    </>
  )
}

export default MenuMobile
