import React from 'react'
import Card from '../../components/Card/Card.js'
import useMediaQuery from '@mui/material/useMediaQuery'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle.js'
import Footer from '../../components/Footer/Footer'
import { Box } from '@mui/material'
import SelectLanguage from 'components/SelectLanguage/SelectLanguage.js'

export default function LegalNotes() {
  const isSmall = useMediaQuery('(max-width: 600px)')

  return (
    <div
      style={{
        minHeight: '98vh',
        backgroundColor: isSmall ? '#fafafa' : ''
      }}
    >
      <Box
        id='skeleton-background'
        style={{ height: isSmall ? '30vh' : '60vh', background: 'lightgray' }}
      />
      <Box sx={{ position: 'absolute', top: '10px', right: '10px' }}>
        <SelectLanguage />
      </Box>

      <div
        style={
          isSmall
            ? presentationStyle.cardContainerMobile
            : presentationStyle.cardContainer
        }
      >
        <Card
          style={Object.assign(
            {},
            presentationStyle.main,
            presentationStyle.mainRaised,
            isSmall
              ? {
                  padding: 10,
                  marginLeft: '0px',
                  marginRight: '0px',
                  borderRadius: '0px',
                  width: '95%'
                }
              : {
                  padding: 50,
                  width: '80%'
                }
          )}
        >
          <div style={{ margin: '0 auto', width: '50%', textAlign: 'center' }}>
            <p>Apra Spa | via G.Brodolini, 12 60035 Jesi (AN) - Italia</p>
            <p>+39 0731 22911 info@apra.it</p>
            <p>P.I. - C.F. 0204351042</p>
            <p>REA n. 157070 Reg. Imp. di AN 02043510425</p>
          </div>

          {<Footer showLink={false} />}
        </Card>
      </div>
    </div>
  )
}
